
export const HOST_PREFIX = process.env.REACT_APP_HOST_PREFIX || 'http://';
export const HOST_SUFFIX = process.env.REACT_APP_HOST_SUFFIX || 'localhost:3000/';
export const DEV_MODE = !!process.env.REACT_APP_DEV_MODE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';


if (DEV_MODE) {
    console.log("DEV_MODE");
}
