import { HOST_PREFIX, HOST_SUFFIX } from './constants';
import Cookies from 'universal-cookie';
const BUILTIN_VERBS = ["get", "post", "delete"];
const HELPERS = {};

HELPERS.auth = (url, options, fetchApi) => fetchApi(url, {auth:true, ...options}, fetchApi);
HELPERS.api = (url, options, fetchApi) => HELPERS.auth(`api/${url}`, options, fetchApi);
[
    "oos", "as", "artsense", "df"
].forEach(
    module => HELPERS[module] = (url, options, fetchApi) => HELPERS.api(`${module}/${url}`, options, fetchApi)
);

const cookies = new Cookies();

/** Fetch enchancing function for calling apis.
 * @param {String} url - url to get resource.
 * @param {Object} options - options object used in fetch.
 * @param {Object} options.json - options object used in fetch.
 * @returns {Promise} resolved returned by fetch().
 */
async function fetchApi(url, options) {
    options = options || {};
    options.headers = options.headers || {};

    if (options.data) {
        options.body = JSON.stringify(options.data);
        options.headers["Content-Type"] = "application/json";
        delete options.data;
    }

    if (options.auth) {
        options.auth = {
          type: "Bearer",
          token: cookies.get('token') || ""
        };

        options.headers.Authorization = `${options.auth.type} ${options.auth.token}`;

        delete options.auth;
    }

    return fetch(`${HOST_PREFIX}${HOST_SUFFIX}${url}`, options)
        .then(response => {

            if (options.json) {
                return response.json();
            }

            return response;
        })
        .catch(error => {
            throw error;
        });
}

[[], ...Object.entries(HELPERS)].forEach(([helperName, helperFn]) => {
    const helper = helperFn ? (url, options) => helperFn(url, options, fetchApi) : fetchApi;
    if (helperName) {
        fetchApi[helperName] = helper;
    }

    BUILTIN_VERBS.forEach(verb => {
        helper[verb] = (url, options) => helper(url, {method:verb, ...options})
    });
});

export default fetchApi;