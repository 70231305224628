import React, { useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom';
import fecthApi from '../../utils/useApi.js'
import { DEV_MODE } from '../../utils/constants.js'

import cookies from './cookies'

const AuthContext = React.createContext()

function _AuthProvider(props){
    const { children } = props;
    const {
        loginLocation,
        token, loading, resetToken, error, resetSuccess,
        resetPassword, login, logout, forgotPassword,
    } = useAuthProviderHooks(props);

    return (
        <AuthContext.Provider value={{
            token,
            loading,
            loginLocation,
            resetToken,
            resetPassword,
            error,
            resetSuccess,
            login,
            logout,
            forgotPassword
        }}>
        {children}
        </AuthContext.Provider>
    )
}

function useAuthProviderHooks({
    location
}) {
    const [token, setToken] = useState(cookies.get('token') || null);
    const [loading, setLoading] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [resetToken, setResetToken] = useState(null);

    const [loginLocation, setLoginLocation] = useState(location ? location.pathname + location.search + location.hash : '');

    function login(institution, username, password) {
        setLoading(true);

        fecthApi('api/login', {
            method: 'POST',
            data: {
                "institution": institution || "",
                "username": username || "",
                "password": password || ""
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){

                var error = json.errors;
                if (json.errors.msg.includes("Unknown database")){
                    error = {"msg": "Institution not found"}
                }
                setError(error);
                setLoading(false);
            } else {
                cookies.set('token', json.data.token, { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})});
                setToken(json.data.token);
                setLoading(false);
            }
        })
    }

    function logout() {
        cookies.remove('token', { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})})

        setToken(null);
        setResetSuccess(false);
        setResetToken(null);
        setError(null);
        setLoading(false);
        setLoginLocation('/');
    }

    function forgotPassword(institution, email) {
        setLoading(true);
        setResetToken(null);

        fecthApi('api/user/forgot-password', {
            method: 'POST',
            data: {
                "institution": institution || "",
                "email": email || ""
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){
                setError(json.errors);
                setLoading(false);
                setResetToken(null);
                setResetSuccess(false);
            } else {
                setError(null);
                setResetToken(json.data.email_job);
                setLoading(false);
                setResetSuccess(false);
            }
        })
    }

    function resetPassword(data) {

        setLoading(true);

        return fecthApi('api/user/reset-password', {
            method: 'POST',
            data: {
                "token": data["token"],
                "institution": data["institution"],
                "new_password": data["newPassword"],
                "confirm_password" : data["confirmPassword"]
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){
                setError(json.errors);
                setLoading(false);
                setResetSuccess(false);
            } else {
                setError(null);
                setResetToken(null);
                setResetSuccess(true);
                setLoading(false);

                return (<Redirect to="/"/>)
            }
        })
    }

    return {
        loginLocation: (!loginLocation || loginLocation === '/login') ? '/' : loginLocation,
        token, loading, resetToken, error, resetSuccess,
        resetPassword, login, logout, forgotPassword,
    }
}

const AuthConsumer = AuthContext.Consumer

const AuthProvider = withRouter(_AuthProvider);

export {
    AuthProvider,
    AuthConsumer
}